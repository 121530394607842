import request from '@/utils/request'
export function search (params) {
  return request({
    url: '/api-manager/interesting-place/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/api-manager/interesting-place/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function getPlace (id) {
  return request({
    url: `/api-manager/interesting-place/${id}`,
    method: 'get'
  })
}

export function createPlace (data) {
  return request({
    url: '/api-manager/interesting-place',
    method: 'post',
    data
  })
}

export function updatePlace (data, id) {
  return request({
    url: `/api-manager/interesting-place/${id}`,
    method: 'put',
    data
  })
}

export function deletePlace (id) {
  return request({
    url: `/api-manager/interesting-place/${id}`,
    method: 'delete'
  })
}

export function filterData (data) {
  return request({
    url: '/api-manager/interesting-place/filterData',
    method: 'post',
    data
  })
}
