<template>
  <div class="app-container">
  <el-row :gutter="4" type="flex" justify="center">
    <el-col :span="20">
      <el-card class="box-card">
        <div slot="header" class="clearfix" justify="center" align="center">
          <strong><span>Nuevo Lugar de Interés</span></strong>
        </div>
        <div class="text item">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <el-form @keypress.enter.native.prevent="handlerCreate" label-position="top" class="size-form-item" label-width="120px" :model="form">
              <el-row justify="space-around" align="middle" :gutter="60">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" align="middle">
                  <el-form-item label="Imagen para Lugar de Interés">
                    <ImageUpload
                      :postImageTo="postImageTo"
                      :getImagesFrom="getGallery"
                      :setImage="form.image"
                      @getImage="getSelectedImage"
                      ></ImageUpload>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" align="middle">
                  <el-form-item label="Imagen de Ícono">
                    <ImageUpload
                      :postImageTo="postImageTo"
                      :getImagesFrom="getGallery"
                      :setImage="form.icon"
                      @getImage="getSelectedIcon"
                      ></ImageUpload>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="middle" :gutter="60">
                <el-col :sm="24" :md="12" :lg="12" align="middle">
                  <el-form-item label="Título">
                    <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.title"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Nombre de Clave">
                    <ValidationProvider name="Nombre de Clave" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.keyName" @input="reformatKey"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Descripción">
                    <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                      <el-input type="textarea" :maxlength="90" :rows="4" v-model="form.description"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
                <el-col :sm="24" :md="12" :lg="12" align="middle">
                  <el-form-item label="Latitud">
                    <ValidationProvider name="Latitud" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.latitude"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Longitud">
                    <ValidationProvider name="Longitud" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.longitude"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Enlace de Ubicación">
                    <ValidationProvider name="Enlace de Ubicación" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.linkMaps" placeholder="https://www.google.com"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="middle" :gutter="60">
                <el-col :sm="24" :md="24" :lg="24" align="middle">
                  <el-form-item label="Sección">
                    <ValidationProvider  name="Seccion" rules="required" v-slot="{ errors }">
                      <el-select
                        v-model="form.section"
                        placeholder="Selecciona un elemento">
                        <el-option
                          v-for="item in sections"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                      <br>
                      <span style="color: #F56C6C; font-size: 12px;">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="middle">
                  <el-col :sm="24" :md="24" :lg="24" align="middle">
                  <span>Categoria</span><br>
                    <el-select
                    v-model="form.category"
                    clearable
                    filterable
                    allow-create
                    placeholder="Elige una opcion">
                    <el-option
                      v-for="item in categories"
                      :key="item.category"
                      :label="item.category"
                      :value="item.category">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row type="flex" justify="center">
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCreate"
                      type="primary"
                      icon="el-icon-upload2"
                      :disabled="invalid">
                      Agregar
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCancel"
                      type="danger">
                      Cancelar
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </ValidationObserver>
        </div>
      </el-card>
    </el-col>
  </el-row>
  </div>
</template>

<script>
import { uploadImageFile } from '@/api/image.js'
import { search } from '@/api/gallery.js'
import ImageUpload from '@/components/imageUpload/ImageUpload.vue'
import { createPlace, filterData } from '@/api/interestingPlaces.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'createGuide',
  components: {
    ImageUpload
  },
  data () {
    return {
      host: urlServer,
      form: {
        title: '',
        description: '',
        section: '',
        image: null,
        icon: null,
        latitude: '',
        longitude: '',
        linkMaps: '',
        keyName: '',
        category: ''
      },
      sections: [
        {
          label: 'Primera Sección',
          value: '1'
        },
        {
          label: 'Segunda Sección',
          value: '2'
        },
        {
          label: 'Tercera Sección',
          value: '3'
        },
        {
          label: 'Cuarta Sección',
          value: '4'
        }
      ],
      categories: [],
      loadingImage: false,
      loadingIcon: false,
      imageSelected: false
    }
  },
  mounted () {
    this.fetchFilterCategories()
  },
  methods: {
    reformatKey () {
      this.form.keyName = this.form.keyName.replace(/\s/g, '_')
    },
    async postImageTo (param) {
      return await uploadImageFile(param)
    },
    async getGallery (param) {
      return await search(param)
    },
    getSelectedImage (imageUploaded) {
      this.form.image = imageUploaded
      console.log('imagen cargada en formulario ' + this.form.image)
    },
    getSelectedIcon (iconUploaded) {
      this.form.icon = iconUploaded
      console.log('icono cargado en formulario ' + this.form.icon)
    },
    handlerCancel () {
      this.$router.push('/interestingPlaces')
    },
    fetchFilterCategories () {
      filterData({ fieldSearch: 'category', status: 'active' })
        .then((response) => {
          this.categories = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handlerCreate (info) {
      console.log(this.form)
      if (this.form.image !== '' && this.form.icon !== '' && this.form.image !== null && this.form.icon !== null) {
        try {
          const response = await createPlace(this.form)
          console.log(this.form)
          if (response.success) {
            console.log(response)
            this.$message({
              showClose: true,
              message: '¡Se creó correctamente!',
              type: 'success'
            })
            this.$router.push('/interestingPlaces')
          } else {
            console.log(response)
          }
        } catch (err) {
          err.response.data.messages.forEach(message => {
            this.$message.error(`${message}`)
          })
        }
      } else {
        this.$message.error('Asegurate que no deba faltar ningún archivo')
      }
    }
  }
}
</script>

<style lang="css" scoped>
.label-form-validate-wrong {
  color: #F56C6C;
}

 .size-form-item > .el-form-item {
  margin: 0;
}
.el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
  line-height: 1.2em;
}
.my-autocomplete {
  width: 500px;
}
</style>
